

.confirm-modal-backdrop {
    opacity: 1 !important;
    background: rgba(0,0,0, 0.2) !important;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2) !important;
    backdrop-filter: blur(5px) !important;
    -webkit-backdrop-filter: blur(5px) !important;
}

/*
    *  Bootstrap 5 Modal z-index fix.  Hack to fix so multiple modals can be show and a bakdrop is shown for each.
    *
*/
@for $i from 1 through 6 {
    $zIndexBackdrop:  #{1000 + (5 * $i)};
    $zIndexContent:  #{1000 + (5 * $i) + 2};
    .offcanvas-backdrop.show:nth-of-type(#{$i}) {
      z-index: $zIndexBackdrop !important;
    }
    .modal-backdrop.show:nth-of-type(#{$i}) {
      z-index: $zIndexBackdrop !important;
    }
    div[role="dialog"][aria-modal="true"]:nth-of-type(#{$i}) {
      z-index: $zIndexContent !important;
    }
  }