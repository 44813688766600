/*  FONT AWESOME  */
// .....

/*  
    Applying Sweet ALert Bootstrap 4 theme.  Wish v 5 was avaialble 
    https://github.com/sweetalert2/sweetalert2-themes/tree/main/bootstrap-4  
*/
@import '/node_modules/@sweetalert2/theme-bootstrap-4/bootstrap-4.scss';

/* React Toastify */  
@import '/node_modules/react-toastify/dist/ReactToastify';
/* set Toastify to Bootstrap Themes by calling bs variable we set */
:root {
    --toastify-color-light:   #{$APP-colors-white};
    --toastify-color-dark:    #{$APP-colors-dark};
    --toastify-color-info:    #{$APP-colors-info};
    --toastify-color-success: #{$APP-colors-success};
    --toastify-color-warning: #{$APP-colors-warning};
    --toastify-color-error:   #{$APP-colors-danger};
}