/* 
    Glassmorphism Effect 
    
    -Makes a Div look like transparent glass

    Can be called with the following classes with different opacities.  The default is 20%:
    .glassmoprhism           (uses default opacity of 20)
    .glass-panel-effect      (uses default opacity of 20)
    .glassmoprhism-10
    .glass-panel-effect-10
    .glassmoprhism-20
    .glass-panel-effect-20
    .glassmoprhism-30
    .glass-panel-effect-30
    .glassmoprhism-40
    .glass-panel-effect-40

*/

.glassmoprhism-10, 
.glass-panel-effect-10  {
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}
.glassmoprhism, 
.glass-panel-effect, 
.glassmoprhism-20, 
.glass-panel-effect-20 {
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}
.glassmoprhism-30, 
.glass-panel-effect-30 
{
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}
.glassmoprhism-40, 
.glass-panel-effect-40 {
    background: rgba(255, 255, 255, 0.4);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}
.glassmoprhism-50, 
.glass-panel-effect-50 {
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}
.glassmoprhism-60, 
.glass-panel-effect-60 {
    background: rgba(255, 255, 255, 0.6);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}
.glassmoprhism-70, 
.glass-panel-effect-70 {
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}
.glassmoprhism-80, 
.glass-panel-effect-80 {
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}
.glassmoprhism-90, 
.glass-panel-effect-90 {
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}