html, body {
    height:100%;    /* Required for footer at bottom  */
}
body {
    position: relative;
    margin: 0;
    box-sizing: border-box;
    
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

 // App is the root div on the SPA. Class name is Set in public/index.html 
.App {
   
    min-height: 100vh;  // Required for footer at bottom  
 //   display: flex;
  //  flex-direction: column;

    // theme-wrapper was added when theme support added, so that becomes the new column to flex to keep footer at bottom. 
    .theme-wrapper {
        min-height: 100vh;  // Required for footer at bottom  
        display: flex;
        flex-direction: column;

        header {
            .navbar-brand img {
                max-width: 155px;  
                height: 38px;
            }
        }

        // <section> - is main content of app between header and footer 
        section {
            padding-top: calc(#{$navbar-brand-height} * 1.8);
            flex: 1;  // Required for footer at bottom  
        }

        // <footer> 
        footer {
            z-index: 1030;
            nav {
                width: 100%;
            }
            .app-footer-copyright {
                font-size: 1em;
            }
        }
    } // end .theme-wrapper
}  // end .App

// sidebar left Main Menu
.main-menu-offcanvas {
    margin-top: calc(#{$navbar-brand-height} * 1.85);  // bs variable for navbar height
    //border-top-right-radius: 12px;
    //border-bottom-right-radius: 12px;
    max-width: 220px;
   // max-height: 600px;

    .offcanvas-title {
        font-size: 1.0rem;
    }
}
// sidebar right Main Menu
.user-menu-offcanvas {
    margin-top: calc(#{$navbar-brand-height} * 1.85);  // bs variable for navbar height
    //border-bottom-left-radius: 12px;
    //border-top-left-radius: 12px;
    max-width: 220px;
  //  max-height: 600px;

    .offcanvas-title {
        font-size: 1.0rem;
    }
}
// Messages Fly Out Menu
.messages-menu-offcanvas {
    margin-top: calc(#{$navbar-brand-height} * 1.85);  // bs variable for navbar height
    
}


code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.ellipsis {
    text-overflow: ellipsis;
  
    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
  }