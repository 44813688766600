.data-field {
    .data-field-label {
        font-size: 11px;
        text-transform: uppercase;
        font-weight: bold;
    }
    .data-field-data {
        padding-left: 20px;
        min-height: 24px;
    }
}
