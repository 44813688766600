.ui-debugger-panel {        /* panel that shows in the UI debugger. */
    border-bottom: 1px solid lightgray; 
    background: lightgreen;
    opacity: 0.9;
    
}


.ui-debugger-panel-floating {
    opacity: 0.7;
    z-index: 2000;
    position: fixed;
    left: 0;
    right: 0;
    top: 0px;
}