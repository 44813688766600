.react-select {
    div[class*="-menu"] {
        z-index: 10 !important;
    }
}

.form-floating .react-select {
    div[class*="-Input"] {
    }
}
//  WHEN IN BOOTSTRAP FLOATING LABELS FORM
.form-floating .react-select {
    padding: 0px !important;
    height: auto !important;
    overflow: visible !important;

    div[class*="-control"] {
        min-height: 56px !important;
        padding: 0px !important;
        margin: 0px !important;
        background-color: var(--bs-body-bg) !important;
        border-color: var(--bs-border-color) !important;
        border-width: var(--bs-border-width) !important;
        border: none !important;
        //border: 1px solid orange !important;
        //box-shadow: $input-focus-box-shadow !important;
        outline: 0 none;

        :focus {
            box-shadow: $input-focus-box-shadow !important;
        }
        input, input:focus, input:active, input:hover {
            -webkit-appearance: none !important;
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
        }
        
        > div:first-child  {
            margin-top: 20px !important;
        }
        div[class*="-ValueContainer"] {
            padding: 0px !important;
            padding-left: 15px !important;
            overflow: visible !important;        
        }
        div[class*="-ValueContainer"] > div:first-child {
            overflow: visible !important;
        }
        div[class*="-placeholder"] {
            margin: 0px !important;
            //grid-area: unset !important;
            padding-left: 3px !important;
        }
        div[class*="-Input"] {
            padding: 0px !important;
          //  border: 1px solid orange !important;
        }
        div[class*="-Input"] input, div[class*="-Input"] input:focus {
            -webkit-appearance: none !important;
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
        }
        
        div[class*="-multiValue"] {
            margin-bottom: 0px !important;
            padding-top: 0px !important;
            padding-bottom: 0px !important;
        }
        div[class*="-indicatorContainer"] {
            padding: 0px !important;
            margin-left: 9px !important;
            margin-right: 9px !important;
        }
     
    }
}
