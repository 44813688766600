@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/variables";


// THEME VARIABLES

$THEME-BODY-bg-color: var(--bs-primary-bg-subtle);
$THEME-HEADER-NAVBAR-bg-color: #8ab1ec;
$THEME-SideMenuLeft-bg-color: var(--bs-tertiary-bg);
$THEME-SideMenuRight-bg-color: var(--bs-tertiary-bg);
$THEME-Modal-bg-color: var(--bs-modal-bg);

$THEME-featured-component-bg-color: $blue-200;
$THEME-featured-component-color: $blue-900;
$THEME-featured-component-border: 1px solid var(--bs-dark-border-subtle);



.app-theme-default {

    background-color: $THEME-BODY-bg-color;

    nav.navbar {
        background-color: $THEME-HEADER-NAVBAR-bg-color;

        button {
           color: black;
           border-color: black;
        }
    }

    /* SIDE MENUS (OFFCANVAS) :
        - Are bootstrap Offcanvas' 
        - They render outside the ReactDom (same with Modal) 
        - So we embed the class of the ThemeWrapper in the Offcanvas.  
        - Therefore it requires a Multi-Class combined selector.  
        - Thats selector in SCSS is the & symbol.
        - As example, below scss compiles to 'app-theme-default.main-menu-offcanvas'
    */
    &.main-menu-offcanvas {
       background-color: $THEME-SideMenuLeft-bg-color !important;
    }
    &.user-menu-offcanvas {
        background-color: $THEME-SideMenuRight-bg-color !important;
    }

    /* MODAL CONTENT - Default Modal colors.
        - Bootstrap Modal is also rendered outside the ReactDom.  
        - So we combine ThemeWrapper and this modal's class.  
    */
    &.modal-content {
        background-color: $THEME-Modal-bg-color !important;
    }
    

    .igsdb-glazing-datagrid {
        color: $THEME-featured-component-color;
        border: $THEME-featured-component-border;
        background-color: $THEME-featured-component-bg-color !important;


        .mdg-meta-text {
            color: $THEME-featured-component-color;
        }

        .mdg-total-records-text {
            font-weight: bold;
        }
        .mdg-filtered-records-text {
            font-style:italic !important;
        }
        .mdg-grid {
            border-radius: 4px;
            background-color: $THEME-BODY-bg-color !important;
        }


        .InovuaReactDataGrid__sort-icon-wrapper {
            margin-right: -10px !important;
        }
    }

    &.igsdb-glazing-datagrid-modal {
        
      //background-color: var(--bs-info-bg-subtle) !important;
   
    }
    
}


