/*
    // Core Local Fonts.

    Declare  Font List below of default and local fonts available to the core. 

*/


@font-face {
    font-family: 'Inter';
    src: url('./Inter/Inter[slnt\,wght].ttf');
    font-display: swap;
}
@font-face {
    font-family: 'Lato';
    src: url('./Lato/Lato-Regular.ttf');
    font-display: swap;
}
@font-face {
    font-family: 'Merriweather';
    src: url('./Merriweather/Merriweather-Regular.ttf');
    font-display: swap;
}
@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat/Montserrat-VariableFont_wght.ttf');
    font-display: swap;
}
@font-face {
    font-family: 'Nunito_Sans';
    src: url('./Nunito_Sans/NunitoSans-VariableFont_YTLC\,opsz\,wdth\,wght.ttf');
    font-display: swap;
}
@font-face {
    font-family: 'Open_Sans';
    src: url('./Open_Sans/OpenSans-VariableFont_wdth\,wght.ttf');
    font-display: swap;
}
@font-face {
    font-family: 'Oswald';
    src: url('./Oswald/Oswald-VariableFont_wght.ttf');
    font-display: swap;
}
@font-face {
	font-family: 'Overpass';
	src: url('./Overpass/Overpass-VariableFont_wght.ttf');
	font-display: swap;
}
@font-face {
    font-family: 'Prompt';
    src: url('./Prompt/Prompt-Light.ttf');
    font-display: swap;
}
@font-face {
    font-family: 'Raleway';
    src: url('./Raleway/Raleway-VariableFont_wght.ttf');
    font-display: swap;
}
@font-face {
    font-family: 'Work_Sans';
    src: url('./Work_Sans/WorkSans-VariableFont_wght.ttf');
    font-display: swap;
}



