.layout-page-header {
    margin-bottom: 10px;
    
    nav, ol {
        margin: 0px;
        padding: 0px;
    }
    .row {
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
    .page-title {
        text-align: center;
        margin-bottom: 15px;
        font-weight: bold;
    }
    hr {
        padding: 0;
        margin: 0;
    }
}