@use "sass:meta";


// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "~bootstrap/scss/functions";

// 2. Include any default variable overrides here
$min-contrast-ratio: 2.5;           // needed to abate bugs from overriding colors below. 

$primary:   $APP-colors-primary;    // #0274bb;    /* blue */
$secondary: $APP-colors-secondary;  // #6c757d;    /* gray  */   
$success:   $APP-colors-success;    // #36b649;    /* green  */    
$info:      $APP-colors-info;       // #4e82a3;    /* light blue */
$warning:   $APP-colors-warning;    // #ffc107;    /* yellow */
$danger:    $APP-colors-danger;     // #c0182a;    /* red */
$dark:      $APP-colors-dark;       // #121212;
$white:     $APP-colors-white;      // #fff;       /* my white */


$font-family-sans-serif: $APP-font-family;
//$font-family-monospace: $APP-body-font-family;   // TODO:  add monospace font implementation here

// 3. Include remainder of required Bootstrap stylesheets
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

// 4. Include any optional Bootstrap components as you like
// @import "~bootstrap/scss/root";
// @import "~bootstrap/scss/reboot";
// @import "~bootstrap/scss/type";
// @import "~bootstrap/scss/images";
// @import "~bootstrap/scss/containers";
// @import "~bootstrap/scss/grid";

// 5. Add additional custom code here


/*  boot strap fixes here:  */


/*  FIX: Safari was showing Button Links with a background and borders.  */
.btn-link {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.nav .active {
    font-weight: bold;;
}