
// OVERRIDE APP COLORS SCHEME
$APP-colors-primary: #0274bb;
$APP-colors-secondary: #6c757d;
$APP-colors-success: #36b649;
$APP-colors-info: #4e82a3;
$APP-colors-warning: #ffc107;
$APP-colors-danger: #c0182a;
$APP-colors-dark: #121212;
$APP-colors-white: #f8f8ff;


// SET THEME STYLE:
.app-theme-dark {


    background-color: $APP-colors-dark;
    color:$APP-colors-white;
    
    // NAVBAR
    nav.navbar {
        background-color:       $APP-colors-dark;

        // NAVBAR BRAND  (logo)
        .navbar-brand {
            color:              $APP-colors-white;
        }

        // NAVBAR BUTTON
        button {
            background-color:   $APP-colors-dark;
            color:              $APP-colors-white;
            border-color:       $APP-colors-white;
            &:focus {
                background-color:   $APP-colors-dark;
            }
            &:hover {
                background-color:   $APP-colors-white;
                color:              $APP-colors-dark;
            }
        }
        
    }
}